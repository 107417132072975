export default {
  BUTTON_PRIMARY_CLASS: 'cm_button button button--primary',
  BUTTON_SECONDARY_CLASS: 'cm_button button button--secondary',

  SEARCH_BOX_CLASS: 'form',
  SEARCH_BOX_INPUT_CLASS: 'form-input form-control',
  SEARCH_BOX_BUTTON_CLASS: 'wb-search-btn',

  SEARCH_BOX_CLEAR_BUTTON: 'Clear',

  PRODUCT_REPEATER_CLASS: 'row pro-row',
};
