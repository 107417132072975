//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-452:_392,_9560,_9740,_8028,_9448,_7488,_8041,_7400;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-452')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-452', "_392,_9560,_9740,_8028,_9448,_7488,_8041,_7400");
        }
      }catch (ex) {
        console.error(ex);
      }